import wagon from  "./images/small/wagon.png";
import hatchback from  "./images/small/hatchback.png";
import sedan from  "./images/small/sedan.png";
import ute from  "./images/small/ute.png";
import suv from  "./images/small/suv.png";
import coupe from  "./images/small/coupe.png";

const smallImage = {
    wagon,
    hatchback,
    sedan,
    ute,
    suv,
    coupe
};

export {smallImage};
