import ordinalInWord from "./ordinal-in-word";
import priceFormatter from "./price-formatter";
import { shortMonthName } from "./get-month-by-number";
import epochToFormattedDate from "./epoch-to-formatted-date";
import makePriceLabel from "./make-price-label";
import { CURRENCY, NUMBER } from "../../constants/app-constants";
import { modelYearNameGenerator } from "./model-year-name-generator";

const getCarName = (details) => {
    const { year, carName } = details;
    return `${year} ${carName}`;
};

const getCarKilometersDrivenLabel = (details) => details.odometerReading && `${priceFormatter(details.odometerReading, true, "ae")} km`;
const getCarOwnersLabel = (details) => details.ownerNumber && `${ordinalInWord(details.ownerNumber)}`;
const getCarPriceLabel = (details, key = "listingPrice", currency = CURRENCY.AU) => makePriceLabel(details[key], currency);
const carProprties = (details) => {
    const {
        fuelType,
        registrationNumber,
        year,
        month,
        insuranceExpiry
    } = details;
    const isValidYear = (year && month);
    const kilometersDriven = getCarKilometersDrivenLabel(details);
    const owners = getCarOwnersLabel(details);
    // eslint-disable-next-line no-magic-numbers
    const buildYear = isValidYear && `${shortMonthName(parseInt(month))}'${(year).toString().substr(2)}`;

    return [
        { title: "Odometer", value: kilometersDriven, type: "kilometer" },
        { title: "Purchased", value: buildYear, type: "buildyear" },
        { title: "Registration", value: registrationNumber, type: "rto" },
        { title: "Fuel Type", value: fuelType, type: "fuel" },
        { title: "Ownership", value: owners, type: "owner" },
        { title: "Insurance",
            value: insuranceExpiry && epochToFormattedDate(insuranceExpiry),
            type: "insurance"
        }
    ];
};
const getTooltipInfoList = (emiDetails = {}) => {
    const {
        comparisonRate,
        tenure,
        interestRate,
        defaultDownPayment
    } = emiDetails || {};
    return [
        `Repayments indicated based on amounts financed with a ${tenure} year loan at an interest rate of <strong>${(interestRate && !isNaN(interestRate)) ? interestRate.toFixed(NUMBER.TWO) : ""}%</strong>, comparison rate of <strong>${(comparisonRate && !isNaN(comparisonRate)) ? comparisonRate.toFixed(NUMBER.TWO) : ""}%</strong> & a deposit of <strong>$${defaultDownPayment}</strong>.`,
        `Comparison rate is based on $30,000 loan amount fixed for a 5-year term & subject to change. Comparison rate is true only for the example given & may not include all fees & charges. Different amounts & terms will result in different comparison rates.`
    ];
};

const getPersonalisedTooltipInfoList = (emiDetails) => {
    const {
        comparisonRate,
        tenure,
        interestRate,
        defaultDownPayment
    } = emiDetails || {};
    return [
        `Repayments indicated based on amounts financed with a ${tenure} year loan at an interest rate of <strong>${(interestRate && !isNaN(interestRate)) ? interestRate.toFixed(NUMBER.TWO) : ""}%</strong>, comparison rate of <strong>${(comparisonRate && !isNaN(comparisonRate)) ? comparisonRate.toFixed(NUMBER.TWO) : ""}%</strong> & a deposit of <strong>$${defaultDownPayment}</strong>. These are based on the details provided as a part of your pre-approval and are subject to change.`,
        `Comparison rate is based on $30,000 loan amount fixed for a 5-year term & subject to change. Comparison rate is true only for the example given & may not include all fees & charges. Different amounts & terms will result in different comparison rates.`
    ];
};

const getFullCarName = (details) => {
    const {
        make = "",
        model = "",
        modelYear = "",
        year = ""
    } = details || {};

    const modelYearName = modelYearNameGenerator(modelYear);

    const carName = `${year} ${make} ${model} ${modelYearName}`;
    return carName;
};
export default carProprties;
export {
    getCarName,
    getCarKilometersDrivenLabel,
    getCarOwnersLabel,
    getCarPriceLabel,
    getTooltipInfoList,
    getPersonalisedTooltipInfoList,
    getFullCarName
};
