import React, { useState } from "react";
import PropTypes from "prop-types";
import VizSensor from "react-visibility-sensor";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { getItemFromSession, setItemInSession } from "../../../utils/helpers/session-storage";
import { EVENT_WINDOW, CAR_VIEWED_SESSION_KEY  } from "./constant";

const TrackableCarCardItem = ({ children, event, eventGA4, eventCallBack, eventAmplitude }) => {
    const { name, data } = event;
    const { name: nameGA4, data: dataGA4 } = eventGA4;
    const { name: eventName, data: properties } = eventAmplitude;
    const [isViewed, setIsViewed] = useState(false);

    const handleAmplitudeEvents = (eventToFire, carProperties) => {
        const carCardsSessionData = getItemFromSession(CAR_VIEWED_SESSION_KEY) || {}; // using session storage for storing car cards info
        let { carCardsViewed = []} =  carCardsSessionData || {};
        const { isFirstImpression = true } = carCardsSessionData || {};
        const updatedCardsViewed = [...carCardsViewed, { ...carProperties }];
        if (updatedCardsViewed.length === 1 && isFirstImpression) {
            carCardsViewed = [];
        } else if (updatedCardsViewed.length === EVENT_WINDOW) {    // Fire event when user views  three  car cards
            const eventObj = {};
            for (let i = 0; i < updatedCardsViewed.length; i++) {
                eventObj[`car${i + 1}`] = updatedCardsViewed[i];
            }
            carCardsViewed = [];
        } else {
            carCardsViewed = [...updatedCardsViewed];
        }
        const updatedSessionData = {
            isFirstImpression: false,
            carCardsViewed
        };

        setItemInSession(CAR_VIEWED_SESSION_KEY, updatedSessionData);

    };

    const onChangeCardVisibility = (isVisible) => {
        if (isVisible && !isViewed) {
            trackDesktopCustomEventsAU(name, data);
            trackDesktopCustomEventsAU(nameGA4, dataGA4);
            handleAmplitudeEvents(eventName, properties);
            if (eventCallBack) {
                eventCallBack();
            }
            setIsViewed(true);
        }
    };

    return (
        <VizSensor
            onChange={onChangeCardVisibility}
        >{children}</VizSensor>
    );
};

TrackableCarCardItem.propTypes = {
    children: PropTypes.object,
    event: PropTypes.object,
    eventGA4: PropTypes.object,
    eventCallBack: PropTypes.func,
    eventAmplitude: PropTypes.object
};

export default TrackableCarCardItem;
