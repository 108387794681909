import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import CloseButton from "../icon-cross";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import Modal from "../modal";

const KbbPriceScreen = ({ onClose, egc, kbbAskingPrice, kbbValueProvidedOn }) => {
    return (
        <Modal isOpen={true}>
            <div styleName={"styles.kbbOuter"}>
                <div styleName={"styles.header"}>
                    <h2>below market price</h2>
                    <CloseButton type="grey" onClickHandler={onClose} />
                </div>
                <div styleName={"styles.kbbTextSection"}>
                    <h4 styleName={"styles.heading"}>How do we determine market price?</h4>
                    <p styleName={"styles.subHeading"}>We partner with Kelley Blue Book (KBB® ) to determine the average price for a used car in the Australian marketplace.</p>
                    <div styleName={"styles.card"}>
                        <div styleName={"styles.priceSection"}>
                            <h5>Typical dealer asking price</h5>
                            <div>
                                <h3>{makePriceLabelRound(kbbAskingPrice)}</h3>
                                <p>Excl. Govt. Charges</p>
                            </div>
                        </div>
                        <div styleName={"styles.priceSection"}>
                            <h5>CARS24 advertised price</h5>
                            <div>
                                <h3>{makePriceLabelRound(egc)}</h3>
                                <p>Excl. Govt. Charges</p>
                            </div>
                        </div>
                        <div styleName={"styles.difference"}>
                            <h4>Difference</h4>
                            <h6>{makePriceLabelRound(kbbAskingPrice - egc)}</h6>
                        </div>
                    </div>
                    <h5 styleName={"styles.text"}>What is Kelley Blue Book® ?</h5>
                    <p styleName={"styles.subText"}>Kelley Blue Book® (KBB) is one of the most trusted sources for valuing cars. KBB provides customers with a comparative guide when purchasing a vehicle of fair-market estimates in the Australian car market.</p>
                    <h5 styleName={"styles.text"}>KBB®  typical dealer asking price range</h5>
                    <p styleName={"styles.subText"}>Updated monthly, the Kelley Blue Book® Typical Dealer Asking Range is representative of dealers’ asking prices for a used car. It assumes that the vehicle has been fully reconditioned and has a clean title history. This price also takes into account the dealers’ profit, costs for advertising, sales commissions and other costs of doing business but excludes applicable tax.</p>
                    <p styleName={"styles.subText"}>The final sale price will likely be less, depending on the car’s actual condition, popularity, type of warranty offered and local market factors. In other words, it is the price you should expect a dealer to ask - not always the price you should pay.</p>
                    <p styleName={"styles.subText"}>©2021 Kelley Blue Book Co., Inc. All rights reserved. As of {kbbValueProvidedOn || "07/28/2022"} Edition for Victoria. Values and pricing are the opinions of Kelley Blue Book, and the actual price of a vehicle may vary.The value and pricing information displayed for a particular vehicle is based upon the specification, kilometers and/or condition information provided by the person generating this report. Kelley Blue Book assumes no responsibility for errors or omissions.</p>
                </div>
            </div>
        </Modal>
    );
};

KbbPriceScreen.propTypes = {
    onClose: PropTypes.func,
    egc: PropTypes.number,
    kbbAskingPrice: PropTypes.number,
    kbbValueProvidedOn: PropTypes.string
};
export default KbbPriceScreen;
