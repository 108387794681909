import { useHistory } from "react-router";
import parseLocationSearchParams from "./parse-location-search-params";

export default () => {
    const history = useHistory();
    const {search} = history.location;
    const queryParams = parseLocationSearchParams(search);
    const searchQuery = queryParams.search;
    return searchQuery;
};
