import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import HeartFilled from "./images/icon-heart-filled.svg";
import HeartEmpty from "./images/icon-heart-empty.svg";

import WishistHeartFilled from "./images/wishist-icon-active.svg";
import WishistHeartEmpty from "./images/wishist-icon.svg";

import HeartOutlined from "./images/heart_white_outline.png";
import HeartOutlinedRed from "./images/heart_red_outline.png";
import WishlistTransparent from "./images/wishlist-transparent.svg";
import WishlistTransparentFilled from "./images/wishlist-transparent-filled.svg";
import CdpRevampGradient from "./images/cdp_revamp_gradient.svg";
import CdpRevampGradientFilled from "./images/cdp_revamp_gradient_filled.svg";

const ICON_SOURCE = {
    "default": {active: HeartFilled, inActive: HeartEmpty},
    "wishlist": {active: WishistHeartFilled, inActive: WishistHeartEmpty},
    "wishlist_clp": {active: HeartOutlinedRed, inActive: HeartOutlined},
    "cdp_revamp": {active: WishlistTransparentFilled, inActive: WishlistTransparent},
    "cdp_revamp_gradient": {active: CdpRevampGradientFilled, inActive: CdpRevampGradient}
};

const IconHeart = ({ active, onClick = () => {}, source = "default", customStyle = [] }) => {
    let wishlistStyles = [];
    customStyle.forEach((item) => {
        wishlistStyles.push(`styles.${item}`);
    });
    wishlistStyles = wishlistStyles.join(" ");
    return (
        <div styleName={`styles.outer ${wishlistStyles}`}>
            <button onClick={onClick}>
                <img width="27" height="24" src={
                    active ? ICON_SOURCE[source].active : ICON_SOURCE[source].inActive
                }
                alt={`${active ? "Remove from" : "Add to"} wishlist`}
                />
            </button>
        </div>
    );
};
IconHeart.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    source: PropTypes.string,
    customStyle: PropTypes.string
};
export default IconHeart;
